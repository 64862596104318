export default () => {
  
  const slider = document.querySelectorAll('.imgscroll-container');   
  const landingPage = document.querySelector('.landing__hero');
  
  if(landingPage){
    slider.forEach(slider => {    
      const slides = slider.querySelectorAll('.slide'); 
      
      const observer = new IntersectionObserver((slide) => {
        slide.forEach((slide) => {
          let indicators = slide.target.closest('.imgscroll-container').querySelector('.onboarding__slide-indicator').querySelectorAll('.indicator__dots');
          let slideIndex = slide.target.getAttribute("data-index");
          indicators[slideIndex-1].classList.toggle('dots-active', slide.isIntersecting);
        })
      },{threshold:0.8,})
      
      slides.forEach((slide) => {
        observer.observe(slide);
      })

    })
  }
  
};