import './font_observer';

import addNav from './add_navigation/index';
import hamburger from './hamburger/index';
import removeHeader from './remove_header/index';
import userDropdown from './user_dropdown/index';
import addImgBtn from './add_image_btn';
import compTriggers from './composer_triggers';
import navIconEffects from './navigation_icons';
import cmsAdditions from './cms/index';
import postTriggers from './post_triggers';
import flashNotice from './flash_notice';
import tagTriggers from './tag_triggers';
import orderSummary from './order_summary';
import firstPost from './first_post';
import postDropdown from './post_dropdown';
import postImgSlide from './post_image_slide';
import postLinkCopy from './post_link_copy';
import disputeForm from './dispute_form';
import stripeModal from './stripe_modal';
import deliveryForm from './delivery_form';
import replyScroll from './reply_scroll';
import directMessages from './direct_messages';
import onboarding from './onboarding';
import onboardingInvite from './onboarding_invite';
import onboardingSliderDots from './onboarding_slider_dots';
import feedShift from './feed_shift';
import submenuHide from './submenu_hide';
import characterLimit from './character_limit';


(() => {
  document.addEventListener('turbo:load', () => {
    addNav();
    hamburger();
    removeHeader();
    userDropdown();
    addImgBtn();
    postDropdown();
    postImgSlide();
    navIconEffects();
    cmsAdditions();
    flashNotice();
    tagTriggers();
    orderSummary();
    firstPost();
    disputeForm();
    stripeModal();
    deliveryForm();
    compTriggers();
    replyScroll();
    directMessages();
    onboarding();
    onboardingInvite();
    onboardingSliderDots();
    feedShift();
    submenuHide();
    characterLimit();
    postTriggers();
	})
})();
