export default () => {
  let postTextField = document.getElementById('post_content');
  let recommendedTags = document.getElementById('recommended_tags');
  let textFieldHasContents = false;
  const postBtn = document.querySelector('#new-post-btn');
  let maxChar
  let textFieldLabel = document.querySelector('label[for="post_content"]');

  if(textFieldLabel){
    maxChar = 1501;
  } else {
    maxChar = 1001;
  }

  if(postTextField){

    ['input', 'keyup'].forEach( event => 
      postTextField.addEventListener(event, () => {
        if(postBtn.value == "Post"){
          recommendedTags.style.display = 'block';
          recommendedTags.style.opacity = '1';
          textFieldHasContents = true;
          postBtn.classList.add('new-post-btn-active');
        }
        if(postTextField.innerText == '' || postTextField.innerText.length > maxChar){
          postBtn.classList.remove('new-post-btn-active');
        }
      }, false)
    );
    
    if(postTextField.innerText){
      recommendedTags.style.display = 'block';
      recommendedTags.style.opacity = '1';
      textFieldHasContents = true;
      postBtn.classList.add('new-post-btn-active');
    };
    
  };



};
