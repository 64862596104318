import { Controller } from "@hotwired/stimulus"

export const getTurbo = async () => {
  if (!window.Turbo) {
    const Turbo = require('@hotwired/turbo')
    await Turbo.start()
    window.Turbo = Turbo
  }
  return window.Turbo
}

getTurbo();

export default class NewPostController extends Controller {
  static targets = ['form', 'tagList', 'tagSearchBox', 'tagSearchSubmitButton'];

  connect() {
    document.addEventListener("trix-change", this.update.bind(this));
  }

  add_tag(event){
    if(event.srcElement.tagName == 'SPAN') {
      var tag = event.srcElement.innerText;
    } else {
      var tag = event.srcElement.parentElement.innerText;
    }
    var currentValue = this.tagListTarget.value;
    if (currentValue != '') {
      this.tagListTarget.value += ', ';
    }
    this.tagListTarget.value += tag;
    this.refreshView();
  }

  paste_url(event) {
    console.log('pasted something');
  }

  refreshView(){
    var editor
    var content
    var lastChar
    var tagList = document.getElementById('post_tag_list').value;
    var userId
    let formData = new FormData()
    
    if(document.getElementById('post_content')){
      editor = document.getElementById('post_content').editor;
      content = editor.getDocument().toString();
      lastChar = content[content.length - 2];
      userId = document.getElementById('post_user_id').value;
      formData.append("post[content]", content);
      formData.append("post[user_id]", userId);
    }

    formData.append("post[tag_list]", tagList);    
    fetch("/ttags/recommendations", {
          body: formData,
          method: 'POST',
          credentials: "include",
          Accept: 'text/vnd.turbo-stream.html',
          headers: {
            "X-CSRF-Token": this.getMetaValue("csrf-token")
          }
    }).then(function(response) {
      if (response.status != 204) {
        return response.text();
      }
    }).then(html => Turbo.renderStreamMessage(html))
  }

  remove_tag(event){
    if(event.srcElement.tagName == 'SPAN') {
      var innerText = event.srcElement.innerText;
    } else {
      var innerText = event.srcElement.parentElement.innerText;
    }
    innerText = innerText.trim();
    var tag = new RegExp(innerText);
    var currentValue = this.tagListTarget.value;
    var newValue = currentValue.replace(tag, '')
      .replace(/^[, ]*/, '')
      .replace(/[, ]*$/, '');
    this.tagListTarget.value = newValue;
    this.refreshView();
  }

  tagSearch(event){
    var content = event.srcElement.value;
    var submitButton = this.tagSearchSubmitButtonTarget;
    let formData = new FormData()
    formData.append("ttags_tag_search[searchterm]", content);
    fetch("/ttags/tag_searches", {
          body: formData,
          method: 'POST',
          credentials: "include",
          Accept: 'text/vnd.turbo-stream.html',
          headers: {
            "X-CSRF-Token": this.getMetaValue("csrf-token")
          }
    }).then(function(response) {
      submitButton.removeAttribute('data-disable-with');
      if (response.status != 204) {
        return response.text();
      }
    }).then(html => Turbo.renderStreamMessage(html))

  }

  update(){
    try{
      var editor = document.getElementById('post_content').editor;
      var content = editor.getDocument().toString();
      var title = document.getElementById('post_title').editor.getDocument().toString();
      var lastChar = content[content.length - 2];
      var userId = document.getElementById('post_user_id').value;
      var tagList = document.getElementById('post_tag_list').value;
      if ((lastChar === ' ') || (lastChar === '.')) {
        let formData = new FormData()
        formData.append("post[content]", content);
        formData.append("post[title]", title);
        formData.append("post[tag_list]", tagList);
        formData.append("post[user_id]", userId);
        if(document.getElementById('post_title')){
          var title_editor = document.getElementById('post_title').editor;
          var title_content = title_editor.getDocument().toString();
          formData.append("post[title]", title_content);
        }
        fetch("/ttags/recommendations", {
              body: formData,
              method: 'POST',
              credentials: "include",
              Accept: 'text/vnd.turbo-stream.html',
              headers: {
                "X-CSRF-Token": this.getMetaValue("csrf-token")
              }
        }).then(function(response) {
          if (response.status != 204) {
            return response.text();
          }
        }).then(html => Turbo.renderStreamMessage(html))
      }
    } catch(_error) {
      return;
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}
