export default () => {
  const postsFeed = document.querySelector('.posts');
  const userProfile = document.querySelector('#user_profile_content'); 
  const tagsPage = document.querySelector('#tags_show');
  let slideImg = document.querySelector('.slide > img');  
  let scrollWidth

  if(slideImg){
    scrollWidth = slideImg.offsetWidth;
  }

  window.addEventListener('resize', () => {
    if(slideImg){
      scrollWidth = slideImg.offsetWidth;
    }
  }, true);


  let imgBtnRight = document.getElementsByClassName('img-slide-right');
  let imgBtnLeft = document.getElementsByClassName('img-slide-left');
  let slideTrack = document.getElementsByClassName('imgscroll-multiple-imgs');
  
  const imgSlideRight = imgSlideIndex => {
    slideTrack[imgSlideIndex].scrollLeft += scrollWidth;
  }

  const imgSlideLeft = imgSlideIndex => {
    slideTrack[imgSlideIndex].scrollLeft -= scrollWidth;
  }

  if(imgBtnRight && imgBtnLeft) {
    [...imgBtnRight].forEach((btn, index) => {
      btn.addEventListener('click', () => {
        imgSlideRight(index)
      });
    });

    [...imgBtnLeft].forEach((btn, index) => {
      btn.addEventListener('click', () => {
        imgSlideLeft(index)
      });
    });

    let observer = new MutationObserver(function(mutations) {   
      
      [...imgBtnRight].forEach((btn, index) => {      
        btn.addEventListener('click', () => {
          imgSlideRight(index)      
        });
      });
      
      [...imgBtnLeft].forEach((btn, index) => {
        btn.addEventListener('click', () => {
          imgSlideLeft(index)
        });
      });
    
    });    
    
    if(postsFeed){
      observer.observe(postsFeed, { 
        attributes: false, 
        childList: true, 
        characterData: false, 
        subtree: true 
      });
    } else if(userProfile){
      observer.observe(userProfile, { 
        attributes: false, 
        childList: true, 
        characterData: false, 
        subtree: true 
      });
    } else if(tagsPage){
      observer.observe(tagsPage, { 
        attributes: false, 
        childList: true, 
        characterData: false, 
        subtree: true 
      });
    }

  }



  
};