import Rails from '@rails/ujs';

if (!window.Rails) {
  Rails.start();
}

import { Controller } from "@hotwired/stimulus"
const homeclass = document.querySelector('.home');

export default class extends Controller {
  static targets = ['entries', 'pagination', 'loading']

  get loading() {
    return this.data.get('loading') == 'true'
  }

  set loading(value) {
    this.data.set('loading', value)
    this.loadingTarget.style.display = value ? 'block' : 'none'
  }

  load() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    let url = next_page.href
    this.loadMore(url)
  }

  scroll() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) {
      return
    }
    let url = next_page.href
    let height_diff = 700

    if (homeclass) {
      height_diff = 900
    }

    var body = document.body,
      html = document.documentElement

    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    if (window.pageYOffset >= (height - window.innerHeight - height_diff)) {
      this.loadMore(url)
    }
  }

  loadMore(url) {
    if (this.loading) return

    this.loading = true
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.loading = false
        // var observer = lozad('.lozad', {
        //               loaded: function(el) {
        //                 el.classList.add('loaded');
        //               }
        //             });
        // observer.observe();
      }
    })

    
  }
}
